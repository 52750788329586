import { createAction, props } from '@ngrx/store';
import { IServiceRegistration } from '../../../shared/model/service-registration.model';

export const loadPastTermRegistrations = createAction(
  '[Past Term Registrations] Load Registrations',
  props<{ supervisorId: string }>()
);

export const loadPastTermRegistrationsSuccess = createAction(
  '[Past Term Registrations] Load Registrations Success',
  props<{ registrations: IServiceRegistration[] }>()
);

export const loadPastTermRegistrationsFailure = createAction(
  '[Past Term Registrations] Load Registrations Failure',
  props<{ error: string }>()
);
